<template>
  <CRow>
    <CCol col="12">
      <template v-if="hasRole('docente-reportes') || hasRole('admin')">
        <h5>Reportes de Estudiantes</h5>
        <div class="row border-bottom mb-2">
          <div class="col-6 col-md-4">
            <div class="card text-center border-primary text-bg-light mb-3">
              <div class="card-body">
                <h5 class="card-title text-primary">BOLET&Iacute;N DE CALIFICACIONES</h5>
                <button type="button" class="btn btn-primary" @click="rptBoletinCalificaciones()">
                  <font-awesome-icon icon="fa-regular fa-file-pdf" size="xl"/>
                  Generar Reporte
                </button>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <div class="card text-center border-dark text-bg-light mb-3">
              <div class="card-body pl-1 pr-1">
                <h5 class="card-title text-dark">BOLETA HABILITACIÓN A EXAMEN</h5>
                <button type="button" class="btn btn-dark" @click="rptBoletaExamen()">
                  <font-awesome-icon icon="fa-regular fa-file-pdf" size="xl"/>
                  Generar Reporte
                </button>
              </div>
            </div>
          </div>
          <div class="col">
          </div>
        </div>
        <h5>Reportes para Título Profesional</h5>
        <div class="row border-bottom mb-2">
          <div class="col-6 col-md-4">
            <div class="card text-center border-warning text-bg-light mb-3">
              <div class="card-body">
                <h5 class="card-title text-warning">HISTORIAL ACADÉMICO</h5>
                <button type="button" class="btn btn-warning" @click="rptHistorialAcademico()">
                  <font-awesome-icon icon="fa-regular fa-file-pdf" size="xl"/>
                  Generar Reporte
                </button>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <div class="card text-center border-success text-bg-light mb-3">
              <div class="card-body">
                <h5 class="card-title text-success">CERTIFICADOS DE NOTAS</h5>
                <button type="button" class="btn btn-success" size="lg" @click="rptCertificadoNotas()">
                  <font-awesome-icon icon="fa-regular fa-file-pdf" size="xl"/>
                  Generar Reporte
                </button>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <div class="card text-center border-info text-bg-light mb-3">
              <div class="card-body pl-1 pr-1">
                <h5 class="card-title text-info">DIPLOMA ACADÉMICO</h5>
                <button type="button" class="btn btn-info" @click="rptTituloProfesional()">
                  <font-awesome-icon icon="fa-regular fa-file-pdf" size="xl"/>
                  Generar Reporte
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="hasRole('docente-reportesnotas') || hasRole('admin')">
        <h5>Reportes de Docentes</h5>
        <div class="row mb-2">
          <div class="col-6 col-md-4">
            <div class="card text-center border-primary text-bg-light mb-3">
              <div class="card-body">
                <h5 class="card-title text-primary">PLANILLA DE CALIFICACIONES</h5>
                <button type="button" class="btn btn-primary" @click="rptPlanillaCalificaciones()">
                  <font-awesome-icon icon="fa-regular fa-file-pdf" size="xl"/>
                  Generar Reporte
                </button>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4"></div>
          <div class="col-6 col-md-4"></div>
        </div>
      </template>
    </CCol>
  </CRow>
</template>

<script>

var sourceLst;
var address;
var modalPdf;

export default {
  name: 'Docentes',
  components: {},
  data: () => {
    return {}
  },
  beforeCreate: function () {
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
  },
  methods: {
    hasRole(roleUser) {
      let roles = localStorage.getItem("roles");
      if (roles != null) {
        roles = roles.split(',')
        if (roles != null && roles.indexOf(roleUser) >= 0) {
          return true
        } else {
          return false;
        }
      }
    },
    rptBoletinCalificaciones() {
      this.$router.push({path: '/docentereportes/boletin'})
    },
    rptCertificadoNotas() {
      this.$router.push({path: '/docentereportes/certificadosnotas'})
    },
    rptHistorialAcademico() {
      this.$router.push({path: '/docentereportes/historialacademico'})
    },
    rptBoletaExamen() {
      this.$router.push({path: '/docentereportes/boletaexamen'})
    },
    rptTituloProfesional() {
      this.$router.push({path: '/docentereportes/tituloprofesional'})
    },
    rptPlanillaCalificaciones() {
      this.$router.push({path: '/docentereportes/planillacalificaciones'})
    },
  },
  computed: {},
  mounted() {
  },
}
</script>